import React from 'react'
import {
    useTheme, Heading, View, Link, Menu, MenuItem, Divider, Text, Flex, MenuButton
} from '@aws-amplify/ui-react'
import { I18n } from 'aws-amplify'
import { storeLanguage, getLanguage, languages } from 'src/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLanguage } from '@fortawesome/free-solid-svg-icons'

export const setLanguage = (language: string) => {
    storeLanguage(language)
    window.location.reload()
}

const buildLanguageMenuItems = ([isoCode, name]: string[]) => {
    return <MenuItem testId={isoCode} key={isoCode} onClick={() => setLanguage(isoCode)}>
        {name}
    </MenuItem>
}

export const SignIn = {
    Header() {
        const { tokens } = useTheme()
        return (
            <>
                <Heading
                    padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                    level={3}
                >
                    <Flex direction="row" justifyContent="space-between">
                        {I18n.get('Sign in to your account')}
                        <Menu
                            trigger={
                                <MenuButton testId='button' style={{maxHeight: '45px'}}>
                                    <FontAwesomeIcon icon={faLanguage} style={{marginRight: '3px'}}/>
                                    {languages[getLanguage()]}
                                </MenuButton>
                            }
                        >
                            {
                                Object.entries(languages).map(buildLanguageMenuItems)
                            }
                        </Menu>
                    </Flex>
                </Heading>
                <View
                    className="subheader"
                    style={{paddingLeft: '2rem', paddingTop: '2rem'}}
                >
                    <Flex direction="row" gap="0.5rem">
                        <Text
                            as={'span'}
                            fontSize={'14px'}
                        >
                            {I18n.get('Want to join Webgains?')}
                        </Text>
                        <Link
                            href="https://www.webgains.com/public/en/about/#ask-a-question-container"
                        >
                            {I18n.get('Join as Advertiser')}
                        </Link>
                        <Divider
                            orientation="vertical" size='small'/>
                        <Link
                            href="https://www.webgains.com/front/user/signup/"
                        >
                            {I18n.get('Join as Publisher')}
                        </Link>
                    </Flex>
                </View>
            </>
        )
    },
}

export default SignIn
