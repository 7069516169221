import {
    Auth, Amplify, Hub,
} from 'aws-amplify'
import {
    Loader, useAuthenticator, withAuthenticator,
} from '@aws-amplify/ui-react'
import '@aws-amplify/ui-react/styles.css'
import React, { useEffect, useState } from 'react'
import { AmplifyUser } from '@aws-amplify/ui'
import config from './config/amplify'
import * as services from './services'
import * as components from './components'
import * as formFields from './formFields'
// eslint-disable-next-line import/named
import { ApiUser, ROLE_NO_LOGIN } from './utils/getUser'
import {
    getUser, mustHaveMFA, getRedirectRoute, LogOutError,
    setupInternationalization,
    getLanguage,
    storeLanguage
} from './utils'

setupInternationalization()

declare module '@aws-amplify/ui/dist/types/types/authenticator/user.js' {
  // eslint-disable-next-line no-shadow
  export interface AmplifyUser {
    preferredMFA: string
  }
}

export function listenToAutoSignInEvent() {
    Hub.listen('auth', ({ payload }) => {
        const { event } = payload
        if (event === 'signOut') {
            const language = getLanguage()
            localStorage.clear()
            storeLanguage(language)
        }
    })
}

Amplify.configure(config)
listenToAutoSignInEvent()

interface middlewareProps {
  cognitoUser: AmplifyUser,
  user: ApiUser | null,
  isUpdatingMfa: boolean,
  setIsUpdatingMfa: (value: boolean) => void
}

type redirectProps = Pick<middlewareProps, 'cognitoUser' | 'user'>;

export function handleRedirect({ cognitoUser, user }: redirectProps) {
    const win: Window = window
    const userSession = cognitoUser.getSignInUserSession()
    if (userSession === null || user === null) {
        throw new LogOutError('User/session not found')
    }
    if (!userSession.isValid()) {
        throw new LogOutError('User session has expired')
    }
    const idToken = userSession.getIdToken().getJwtToken()
    const hashParam = `#id_token=${idToken}`
    const route = getRedirectRoute(user)
    win.location.replace(`${route}${hashParam}`)
}

export function middleware({
    cognitoUser, user, isUpdatingMfa, setIsUpdatingMfa,
}: middlewareProps): Array<Promise<string>> {
    const promises = []
    if (cognitoUser.challengeName === 'MFA_SETUP' && isUpdatingMfa === false) {
        setIsUpdatingMfa(true)
        promises.push(Auth.setPreferredMFA(cognitoUser, 'TOTP'))
    } else if (user === null || (cognitoUser.preferredMFA === 'NOMFA' && mustHaveMFA(user))) {
        promises.push(Promise.reject(new LogOutError('User MFA is not set')))
    } else if (user.role_id === ROLE_NO_LOGIN) {
        promises.push(Promise.reject(new LogOutError('User has no login')))
    }
    return promises
}

const loaderStyleProps = {
    style: {
        marginLeft: '42%',
        marginTop: '42%',
    },
    width: '5rem',
    height: '5rem',
}

export function App() {
    const [isUpdatingMfa, setIsUpdatingMfa] = useState(false)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { user: cognitoUser, signOut }: {user: any, signOut: () => void} = useAuthenticator((context) => [context.user])
    useEffect(() => {
        getUser(cognitoUser).then((user: ApiUser|null) => {
            const promises = middleware({
                user, cognitoUser, isUpdatingMfa, setIsUpdatingMfa,
            })
            Promise.all(promises)
                .then(() => handleRedirect({
                    cognitoUser,
                    user,
                })).catch(signOut)
        }).catch(signOut)
    }, [])
    return (
        <main>
            <Loader {...loaderStyleProps} />
        </main>
    )
}

export default withAuthenticator(
    App,
    {
        variation: 'default',
        loginMechanisms: ['email'],
        hideSignUp: true,
        services,
        components,
        formFields,
    },
)
