import { DefaultTexts } from "@aws-amplify/ui"

const RESET_PASSWORD_PATH = '/api/password/send-reset-email'

export async function handleForgotPassword(email: string): Promise<void> {
    const postData = JSON.stringify({
        email,
        redirect_to: `https://${window.location.host}`,
    })
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_PLATFORM_API_URL}${RESET_PASSWORD_PATH}`, {
            headers: {
                'content-type': 'application/json',
                'content-length': postData.length.toString(),
            },
            body: postData,
            method: 'POST',
        }).then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    return resolve()
                } if (response.error) {
                    throw new Error(response.error)
                } else {
                    throw new Error(DefaultTexts.CODE_ARRIVAL)
                }
            })
            .catch(reject)
    })
}

export default handleForgotPassword
