import { CognitoUser } from 'amazon-cognito-identity-js'
import { I18n } from 'aws-amplify'

export const ROLE_ADMIN = 50
export const ROLE_MANAGER = 30
export const ROLE_EDITOR = 20
export const ROLE_VIEWER = 10
export const ROLE_UNVERIFIED = 5
export const ROLE_NO_LOGIN = 0

export const roles = [ROLE_NO_LOGIN, ROLE_UNVERIFIED, ROLE_VIEWER, ROLE_EDITOR, ROLE_MANAGER, ROLE_ADMIN] as const
export type ROLES = typeof roles[number]

export interface ApiUser {
  role_id: ROLES,
  affiliate_user_id?: string,
  advertiser_user_id?: string,
  admin_user_id?: string,
  agency_user_id?: string,
}

export const CACHE_KEY_PPREFIX = 'webgains-auth-'

const USER_PATH = '/auth/user'

const getKeyName = (id: string): string => `${CACHE_KEY_PPREFIX}${id}`

export function getUser(cognitoUser: Pick<CognitoUser, 'getSignInUserSession'>): Promise<ApiUser|null> {
    const userSession = cognitoUser.getSignInUserSession()
    return new Promise((resolve, reject) => {
        if (userSession === null || !userSession.isValid()) {
            resolve(null)
            return
        }
        const idToken = userSession.getIdToken().getJwtToken()
        const [, payload] = idToken.split('.')
        const { 'custom:platform_user_id': platformApiId } = JSON.parse(atob(payload))
        const cacheData = localStorage.getItem(getKeyName(platformApiId))
        if (cacheData !== null) {
            resolve(JSON.parse(cacheData))
            return
        }
        fetch(`${process.env.REACT_APP_PLATFORM_API_URL}${USER_PATH}`, {
            headers: {
                accept: 'application/json',
                authorization: `Bearer ${idToken}`,
            },
            method: 'GET',
        }).then((r) => r.json())
            .then((r) => {
                if  (r.error) {
                    reject(I18n.get('Invalid user'))
                    return
                }
                localStorage.setItem(getKeyName(platformApiId), JSON.stringify(r))
                resolve(r)
            }).catch(reject)
    })
}
