/* eslint-disable no-param-reassign */
import { Auth } from 'aws-amplify'
import { getUser, mustHaveMFA, ROLE_NO_LOGIN } from '../utils'

// Auth.signIn returns any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function setMFARequirements(cognitoUser: any) {
    cognitoUser.challengeName = 'MFA_SETUP'
    cognitoUser.challengeParam = {
        MFAS_CAN_SETUP: '["SOFTWARE_TOKEN_MFA"]',
        USER_ID_FOR_SRP: cognitoUser.attributes.sub,
    }
}

export async function handleSignIn({ username, password }: {username: string, password: string}): Promise<unknown> {
    const cognitoUser = await Auth.signIn(username, password)
    return new Promise((resolve, reject) => {
        if (cognitoUser.signInUserSession === null) {
            resolve(cognitoUser)
            return
        }
        getUser(cognitoUser).then((apiUser) => {
            if (apiUser === null) {
                throw new Error('Invalid user')
            }
            if (apiUser.role_id === ROLE_NO_LOGIN) {
                Auth.signOut()
                throw new Error('Your account has been disabled.'
                +' If you think this is an error, please contact support@webgains.com.')
            }
            if (process.env.REACT_APP_DISABLE_MFA !== 'true' && cognitoUser.preferredMFA === 'NOMFA' && mustHaveMFA(apiUser)) {
                setMFARequirements(cognitoUser)
            }
            return resolve(cognitoUser)
        }).catch(reject)
    })
}

export default handleSignIn
